import React from "react";
import {
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu, IonMenuToggle, IonNote,
    IonTitle, IonToggle,
    IonToolbar
} from "@ionic/react";
import {
    airplane,
    bluetooth,
    call,
    cloudDownloadOutline,
    constructOutline,
    libraryOutline,
    walletOutline,
    wifi
} from "ionicons/icons";


const SideBar: React.FC = () => {
    return <IonMenu contentId="main-content">
        <IonHeader>
            <IonToolbar color="tertiary">
                <IonTitle>Hauptmenu</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
            <IonList className={"sideBarContent"} >
                 <IonListHeader>
                     Toolbox
                 </IonListHeader>

                    <IonItem routerLink="/app/faq">
                            <IonIcon aria-hidden="true" icon={libraryOutline} slot="start"></IonIcon>
                            <IonLabel>FAQ</IonLabel>
                    </IonItem>

                    <IonItem routerLink="/app/download">
                        <IonIcon aria-hidden="true" icon={cloudDownloadOutline} slot="start"></IonIcon>
                        <IonLabel>Download</IonLabel>
                    </IonItem>

                    <IonItem routerLink="/app/tutorial">
                        <IonIcon aria-hidden="true" icon={constructOutline} slot="start"></IonIcon>
                        <IonLabel>Tutorial</IonLabel>
                    </IonItem>

                <IonListHeader>
                    Verschiedenes
                </IonListHeader>
                <IonItem >
                    <IonIcon aria-hidden="true" icon={walletOutline} slot="start"></IonIcon>
                    <IonLabel>Payment</IonLabel>
                </IonItem>
                {/*
                <IonItem>
                    <IonToggle>
                    </IonToggle>
                    <IonLabel color={"white"}>Nachrichten erlauben</IonLabel>
                </IonItem>
                */}
            </IonList>
        </IonContent>
    </IonMenu>
}

export default SideBar;