import "./AppHeader.css";
import { useAuth0 } from "@auth0/auth0-react";
import useTranslator from "../components/useTranslator";
import {
  IonAvatar, IonButton, IonButtons,
  IonHeader, IonItem, IonList, IonMenuButton, IonSelect, IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import React, {useEffect, useState} from "react";
import {Login, Utils} from "./Utils";
import {GlobalStateContext, DispatchStateContext} from "../App";

interface AppHeaderProps {
  title?: string;
}



const customPopoverOptions = (translator: any) => {
  return {
    cssClass: "account-popover",
    header: translator.translate("appHeader.header"),
    subHeader: translator.translate("appHeader.subHeader"),
    message: translator.translate("appHeader.message"),
  }
};



export const AppHeader: React.FC<AppHeaderProps> = (props:AppHeaderProps) => {
  const useGlobalState = () => [
    React.useContext(GlobalStateContext),
    React.useContext(DispatchStateContext)
  ];
  const translator = useTranslator();
  const { user, getAccessTokenSilently } = useAuth0();
  const [accountsData, setAccountsData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  //@ts-ignore
  const [state, dispatch] = useGlobalState();
  const getAccountData = async (url:string) => {
    Utils.accessToken = await getAccessTokenSilently();
    let res =  await Utils.readApi(url);
    console.log("RES: ", res);
    setAccountSelection(res?.responseData);
    setAccountsData(res?.responseData);
  }

  const setAccountSelection = async (accountsInfo:object) => {
    const chartType = window.localStorage.candleChart || false;
    //dispatch({ candleChart: storedChartType });
    const storedAccount = window.localStorage.accountNumber;
      //console.log("AccountNo: ", storedAccount);
      let found = false;
      let count = 0;
      for (let i in accountsInfo) {
        count ++;
        if(storedAccount.toString() === accountsInfo[i]['accountNumber'].toString())
          found = true;
      }
      if(found) {
        setSelectedAccount(storedAccount);
         //@ts-ignore
        dispatch({ accountNumber: storedAccount,candleChart: chartType });
      } else {
        if(count > 0) {
          setSelectedAccount(accountsInfo[0]['accountNumber']);
          //@ts-ignore
          //dispatch({ accountNumber: accountsInfo[0]['accountNumber'] });
        }
        // set the first one
      }
  }

  const onChangeAccount = (account:string) => {
      //console.log("onChange: ", account);
      localStorage.setItem("accountNumber", account);
    // @ts-ignore
    setSelectedAccount(account);
      // @ts-ignore
      dispatch({ accountNumber: account });
  }


  useEffect(()=>{
    if(user) {
      getAccountData("/accounts");
    }

  }, []);

  //console.log("STATE: ", state, selectedAccount);


  return <IonHeader>

    <IonToolbar className={'headerToolbar'}>
      <IonButtons slot="end">
        <IonMenuButton></IonMenuButton>
      </IonButtons>
      {user && <IonButton routerDirection="forward" routerLink={"/app/Profile"}  fill="clear" style={{float:'left', height:'100%'}}>
        <IonAvatar slot="start" style={{height:40, width:40, marginLeft:8, marginTop:4 }}> <img src={user.picture} alt={user.name} /></IonAvatar></IonButton>}

      {user ? <IonList lines="none" style={{marginTop:0,paddingTop:0, backgroundColor: 'transparent'}}>
        <IonItem style={{backgroundColor:'transparent'}}>
          {/*interface="popover"*/}
          <IonSelect className={"accountSelect"} interfaceOptions={customPopoverOptions(translator)} value={selectedAccount.toString()} placeholder={translator.translate("appHeader.accountSelectPlaceholder")} onIonChange={e => {onChangeAccount(e.detail.value);}}>
            {Object.keys(accountsData).map(function(account, key) {
              return <IonSelectOption key={accountsData[account]['accountNumber']} value={accountsData[account]['accountNumber'].toString()}>{accountsData[account]['accountNumber']} ({accountsData[account]['brokerName']})</IonSelectOption>
            })}
          </IonSelect>
        </IonItem>
      </IonList> : <IonTitle><img alt={"FollowMe"} onClick={async () => {await Login}} style={{maxHeight:46}} src="../assets/FollowMe.png" /></IonTitle>}


      {/*
      <IonButtons slot="end">  */}
        {/*user &&
            <><IonTabButton tab="contact" href="/contact">
              <IonIcon icon={chatbubble} />
            </IonTabButton><IonBadge slot="end" style={{
              borderRadius: '50%',
              minWidth: 19,
              position: 'absolute',
              right: 0,
              zIndex: 1,
              top: -6
            }}>1</IonBadge></> */}
      {/*</IonButtons>*/}
    </IonToolbar>


    {/*
    <IonToolbar>

      <IonItem>
        {user && <IonAvatar slot="start"> <img src={user.picture} alt={user.name} /> </IonAvatar>}
          {props.title ? <IonTitle>{props.title}</IonTitle> : <IonTitle><img style={{maxHeight:46}} src="./assets/FollowMe.png" /></IonTitle>}
        {user ?
            <IonTabButton tab="contact" href="/contact">
            <IonIcon icon={chatbubble} />
          </IonTabButton> : <LoginButton/> }
      </IonItem>
    </IonToolbar>
    */}
  </IonHeader>;
};

