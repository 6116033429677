import {
    IonCard, IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol,
    IonContent, IonGrid, IonLabel,
    IonPage, IonRow, IonSegment, IonSegmentButton, IonToggle
} from "@ionic/react";

import { useAuth0 } from "@auth0/auth0-react";
import "./Home.css";

import React, {useEffect, useState} from "react";
import { AppHeader } from "../components/AppHeader";
import useTranslator from "../components/useTranslator";
import {GetCandleChartOptions, GetCandleTimeLineOptions, Utils} from "../components/Utils";
import 'chart.js/auto';
import {Bar, Line} from "react-chartjs-2";
import "./Overview.css";
import i18n from "../i18n";
import Chart from "react-apexcharts";
import moment from 'moment';
import Loader from "../components/Loader";
import {DispatchStateContext, GlobalStateContext} from "../App";
import {ChartOptions} from "chart.js/dist/types";
import SideBar from "../components/SideBar";

const Overview: React.FC = () => {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const translator = useTranslator();
    const [apiData, setApiData] = useState([]);
    const [candleData, setCandleData] = useState([{x: String,y: Array<any>}]);
    const [tableViewData, settableViewData] = useState([{label:"",pl:0}]);
    const [brushData, setBrushData] = useState(Array<any>);
    const [brushCategories, setBrushCategories] = useState(Array<any>);
    const [range, setRange] = useState(30);
    const [profitLossData,setProfitLossData] = useState({
        labels: [""],
        datasets: [
            {
                label: "",
                data: [0],
                fill: true,
                backgroundColor: ""
            }
        ]
    });
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [candleChart, setCandleChart] = useState(true);

    const useGlobalState = () => [
        React.useContext(GlobalStateContext),
        React.useContext(DispatchStateContext)
    ];

    const [state, dispatch] = useGlobalState();


    if(state) {
        if ("accountNumber" in state) {
            let account = "";
            account = state?.accountNumber;
            if (account.toString() !== selectedAccount.toString()) {
                setSelectedAccount(account as never);
            }
        }

        if ("candleChart" in state) {
            //@ts-ignore
            if(candleChart !== JSON.parse(state?.candleChart.toString()))
                setCandleChart(JSON.parse(state?.candleChart.toString()));
        }


    }


    //for line chart
    const [accountPerformanceData,setAccountPerformanceData] = useState({
        labels: [""],
        datasets: [
            {
                label: "",
                data: [0],
                fill: true,
                backgroundColor: ""
            }
        ]
    });


    useEffect(()=>{
        if(selectedAccount.toString() !== "")
            getDataFromApi("/trades/trade-results/" + selectedAccount);
    }, [candleChart,selectedAccount,range]);

    useEffect(()=>{
        getCandleData();
        getTradeResults();
    }, [range, apiData]);

    const getDataFromApi = async (url:string) => {
        Utils.accessToken = await getAccessTokenSilently();
        let res =  await Utils.readApi(url);
        res?.responseData?.reverse();
        console.log("RESULT: ", res);
        // @ts-ignore
        setApiData(res);
    }

    const getFromDay = (today:Date) => {
        let fromDay: Date;
        switch(range) {
            case 7:
                fromDay = moment(today).subtract(7, 'd').toDate();
                break;
            case 30:
                fromDay = moment(today).subtract(1, 'M').toDate();
                break;
            case 90:
                fromDay = moment(today).subtract(3, 'M').toDate();
                break;
            case 180:
                fromDay = moment(today).subtract(6, 'M').toDate();
                break;
            case 365:
                fromDay = moment(today).subtract(1, 'y').toDate();
                break;
            default:
                fromDay = moment(today).subtract(1, 'M').toDate();
        }
        return fromDay;
    }

    const getCandleData = () => {
        let responseData = [];
        const today = new Date();
        const fromDay = getFromDay(today);

        if(apiData.hasOwnProperty('responseData')) {

            //for line chart
            if(!candleChart) {
                //@ts-ignore
                responseData = apiData?.responseData?.filter((item: any) => {
                    return new Date(item.tradingDate).getTime() <= today.getTime() &&
                           new Date(item.tradingDate).getTime() >= fromDay.getTime();
                }) || [];
            }

            if(candleChart) {
                //@ts-ignore
                responseData = apiData?.responseData;
            }

        }
        let candles = [];
        let brushDataPoints = [];
        let brushCategoryPoints = [];
        // calculate o,h,l,c
        let i = 0
        for (const item of responseData){
            let o=0;
            let h=0;
            let l=0;
            let c= 0;
            if(i > 0)
                o = candles[i-1].y[3];

            let totalProfit = parseFloat(item?.totalProfit);
            if (isNaN(totalProfit))
                totalProfit = 0;

                c = o + totalProfit;
                if (totalProfit  > 0) {
                    l = o - (c - o) * .25;
                    h = c + (c - o) * .25;
                } else {
                    l = c + (c - o) * .25;
                    h = o - (c - o) * .25;
                }

                candles.push({
                    x: new Date(item.tradingDate),
                    y: [Math.round(o*100)/100, Math.round(h*100)/100, Math.round(l*100)/100, Math.round(c*100)/100]
                })

                brushDataPoints.push({x: new Date(item.tradingDate),y:[Math.round(totalProfit)]});
                brushCategoryPoints.push(new Date(item?.tradingDate));

            i++;
        }
        //console.log("Candles: ", candles);
        // @ts-ignore
        setCandleData(candles);
        setBrushData(brushDataPoints);
        setBrushCategories(brushCategoryPoints);
    }

    const getTradeResults = () => {
        let responseData = [];
        const today = new Date();
        const fromDay = getFromDay(today);

        if(apiData.hasOwnProperty('responseData'))
            //@ts-ignore
            responseData = apiData?.responseData?.filter((item: any) => {
                return new Date(item.tradingDate).getTime() <= today.getTime() &&
                    new Date(item.tradingDate).getTime() >= fromDay.getTime();
            }) || [];

        let labels = [];
        let plData = []
        let plDataBackground = [];
        //for line chart
        let apData = [];
        let lastAp = 0;
        //end
        let tableView = [];

        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        for (const item of responseData) {
            if (labels.length < range) {
                labels.push(new Date(item?.tradingDate).toLocaleDateString(i18n.language));
                plData.push(item?.totalProfit );
                item?.totalProfit >= 0 ? plDataBackground.push("#3880ff") : plDataBackground.push("#f4623a");
                //for line chart
                if(!candleChart) {
                    lastAp = lastAp + (item?.totalProfit );
                    apData.push(lastAp);
                }
                //end
                // @ts-ignore
                tableView.push({label:new Date(item?.tradingDate).toLocaleDateString(i18n.language, options), pl:item?.totalProfit });
            }
        }

        const profitData = {
            labels: labels,
            datasets: [
                {
                    label: "Profit/loss",
                    data: plData,
                    fill: true,
                    backgroundColor: plDataBackground,
                }
            ]
        };

        //for line chart
        const accountPerformance = {
            labels: labels,
            datasets: [
                {
                    label: translator.translate("overview.accountPerformance"),
                    data: apData,
                    fill: false,
                    backgroundColor: "",
                }
            ]
        };
        setAccountPerformanceData({...accountPerformance});
        //end

        // @ts-ignore
        setProfitLossData({...profitData});
        settableViewData(tableView.reverse());
    }

    const barChartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                    callbacks: {
                        title: (tooltipItem:any) => {
                            return tooltipItem[0].label;
                        },
                        label: function(tooltipItem:any) {
                            return tooltipItem.raw >= 0 ? translator.translate("overview.profit") + ": " + tooltipItem.formattedValue + " €": translator.translate("overview.loss") + ": " + tooltipItem.formattedValue + " €";
                        },
                    },
                    backgroundColor: '#f6f6f6',
                    padding: 8,
                    titleFont: {size:14,weight:'bold'},
                    titleColor: '#199BE4',
                    bodyColor: '#898989',
                    bodyFont: {size: 14},
                    displayColors: false
                }
            }
        }

    const adjustRange = (new_range:number) => {
        setRange(new_range);
    }


    if (isLoading) {
        return <Loader/>;
    }

    const viewRanges = [7,30,90,365];

    const candleChartOptions = GetCandleChartOptions();
    const candleTimeLineOptions = GetCandleTimeLineOptions();
    candleTimeLineOptions.xaxis.categories = brushCategories;

    //candleTimeLineOptions.yaxis.min = Math.min(...brushData.map(o => o.y));
    //candleTimeLineOptions.yaxis.max = Math.max(...brushData.map(o => o.y));

    const today = new Date();
    const max = today.getTime() // Current timestamp
    //@ts-ignore
    let firstDate = new Date(candleData?.[0]?.['x']) as const;
    const min = Math.max(getFromDay(today).getTime(), firstDate.getTime());  // scale max back to the last candle time
    //const min = Math.max(getFromDay(today).getTime());
    const candleChartRange = max - min;
    //@ts-ignore
    candleChartOptions.xaxis.range = candleChartRange;
    candleChartOptions.xaxis.min = min;
    candleChartOptions.xaxis.max = max;

    candleChartOptions.chart.zoomX =
    {
            min,
            max
    }


    const lineChartOptions: ChartOptions<"line"> = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };


    candleTimeLineOptions.chart.selection.xaxis.min = min;
    candleTimeLineOptions.chart.selection.xaxis.max = max;

    const toggleChart = (value:boolean) => {
        localStorage.setItem("candleChart", (value).toString());
        //@ts-ignore
        dispatch({ candleChart: value });
        setCandleChart(value);
    }
    /*
    console.log("candleData", candleData.length);
    console.log("accountPerformanceData", accountPerformanceData.labels.length);
    console.log("profitLossData", profitLossData.labels.length);
    */
    return (
        <>
        <SideBar/>
    <IonPage id="main-content">

      <AppHeader title={translator.translate('overview.title')}/>
      <IonContent fullscreen>

          <IonSegment className={"rangeSelector"} color="primary" value={range.toString()}>
              {viewRanges.map((range)=>{
              return <IonSegmentButton key={range} value={range.toString()} onClick={()=>adjustRange(range)}>
                  <IonLabel>{translator.translate('overview.buttonTitle_' + range)}</IonLabel>
              </IonSegmentButton>
              })}
              <IonToggle style={{paddingTop: '1rem'}} onIonChange={(e) => toggleChart(e.detail.checked)}  checked={candleChart} aria-label="Candle chart"></IonToggle>
          </IonSegment>

          <IonCard>
              <IonCardHeader>
                  <IonCardTitle>{translator.translate('overview.accountPerformance')}</IonCardTitle>
                  {/*<IonCardSubtitle>{translator.translate('overview.temporalDevelopment_' + range)}</IonCardSubtitle>*/}
              </IonCardHeader>
              <IonCardContent>

                  {candleChart === true && <Chart id={'chart'} type={"candlestick"} height="360" series={[{data : candleData}]} options={candleChartOptions}></Chart>}

                  {candleChart === false && accountPerformanceData.hasOwnProperty('datasets') && <Line data={accountPerformanceData} options={lineChartOptions}/>}

                  {candleChart === true &&
                      <Chart
                          id={'timeline-chart'}
                          options={candleTimeLineOptions}
                          series={[{data : brushData}]}
                          type="bar"
                          height="160"
                          style={{marginTop: -35}}
                      />
                  }

              </IonCardContent>
              </IonCard>

          {candleChart === false &&
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{translator.translate('overview.tradingHistory')}</IonCardTitle>
                {/*<IonCardSubtitle>{translator.translate('overview.temporalDevelopment_' + range)}</IonCardSubtitle>*/}
            </IonCardHeader>
            <IonCardContent>{profitLossData.hasOwnProperty('datasets') && <Bar data={profitLossData} options={barChartOptions}/>}</IonCardContent>
          </IonCard>
          }


          <IonCard>
              <IonCardHeader>
                  <IonCardTitle>{translator.translate('overview.tradeSumTitle')}</IonCardTitle>
                  {/*<IonCardSubtitle>{translator.translate('overview.temporalDevelopment_' + range)}</IonCardSubtitle>*/}
              </IonCardHeader>
              <IonCardContent>
                  <IonGrid>
                      <IonRow className={'table_head'} key={'headline'}>
                          <IonCol style={{textAlign:'right'}}>{translator.translate('overview.tradingDate')}</IonCol>
                          <IonCol style={{textAlign:'right'}}>{translator.translate('overview.profitLoss')}</IonCol>
                      </IonRow>
                      {tableViewData.map((row,idx) => (
                          <IonRow key={idx} class={"data-row"}>
                              <IonCol>{row.label}</IonCol>
                              <IonCol className={row.pl < 0 ? 'negative' : 'positive'}>{row.pl.toFixed(2)} €</IonCol>
                          </IonRow>
                      ))}
                  </IonGrid>
              </IonCardContent>
          </IonCard>


      </IonContent>
    </IonPage>
        </>
  );
};

export default Overview;
