import {
    IonCard,
    IonCardContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol,
    IonContent, IonGrid,
    IonPage, IonRow,
} from "@ionic/react";

import { useAuth0 } from "@auth0/auth0-react";
import useTranslator from "../components/useTranslator";
import "./Home.css";
import {AppHeader} from "../components/AppHeader";
import React, {useEffect, useState} from "react";
import Loader from "../components/Loader";
import {Utils} from "../components/Utils";
import {DispatchStateContext, GlobalStateContext} from "../App";
import SideBar from "../components/SideBar";

const Invoice: React.FC = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const translator = useTranslator();
  //const [apiData, setApiData] = useState([]);
  const [billing, setBilling] = useState({});
  const [range, setRange] = useState(52);

    const [selectedAccount, setSelectedAccount] = useState([]);

    const useGlobalState = () => [
        React.useContext(GlobalStateContext),
        React.useContext(DispatchStateContext)
    ];

    const [state, dispatch] = useGlobalState();
    if(state) {
        if ("accountNumber" in state) {
            let account;
            account = state?.accountNumber;
            if (account.toString() !== selectedAccount.toString()) {
                setSelectedAccount(account as never);
            }
        }
    }

    useEffect(()=>{
        getDataFromApi("/accounting/" + selectedAccount);
    }, [selectedAccount, range]);

    const getDataFromApi = async (url:string) => {
        Utils.accessToken = await getAccessTokenSilently();
        let res =  await Utils.readApi(url);
        //res?.responseData?.reverse();
        //console.log("API-DATA: ", res);

        prepareAccountingData(res.responseData);
        // @ts-ignore
        //setApiData(res);
    }

    const reverseObj = (obj:object) => {
        let newObj = {}

        Object.keys(obj)
            .sort()
            .reverse()
            .forEach((key) => {
                //console.log(key)
                newObj[key.toString() + " "] = obj[key]
            })

        //console.log("ReverseObject: ",newObj)
        return newObj
    }

    const prepareAccountingData =  (responseData:Array<object>) => {
        let billing: object[] = [];
        //Object.keys(responseData).forEach(function(key,index) {
            const account = {};
            //account["account"] = key;

            responseData.forEach((item:object) => {
                if(!account.hasOwnProperty(item["week"])) {
                    account[item["week"]] = [];
                }
                account[item["week"]].push(item);
            });
            billing[selectedAccount.toString()] = reverseObj(account);
        //});
        //console.log("The Billing: ", billing);
        setBilling(billing);
    }

  if (isLoading) {
    return <Loader/>;
  }


  return (
      <>
          <SideBar/>
          <IonPage id="main-content">
      <AppHeader title={translator.translate('invoice.title')}/>
      <IonContent fullscreen>

          {Object.keys(billing).map(function(accountNumber, idx) {
              return (<IonCard  key={idx}>
                  <IonCardHeader>
                      <IonCardTitle>{translator.translate('invoice.overviewTitle')}</IonCardTitle>
                      {/*<IonCardSubtitle>{translator.translate('invoice.overviewSubTitle')}: {accountNumber}</IonCardSubtitle>*/}
                  </IonCardHeader>
                  <IonCardContent>
                      <IonGrid>
                          <IonRow className={'table_head'} key={'headline'}>
                              <IonCol
                              style={{textAlign: 'center'}}>{translator.translate('invoice.calendarWeek')}</IonCol>

                              <IonCol
                                  style={{textAlign: 'center'}}>{translator.translate('invoice.instrument')}</IonCol>
                              <IonCol
                                  style={{textAlign: 'right'}}>{translator.translate('invoice.tradeResultInstrument')}</IonCol>
                              <IonCol
                                  style={{textAlign: 'right'}}>{translator.translate('invoice.provisionInstrument')}</IonCol>
                          </IonRow>

                          {Object.keys(billing[accountNumber]).map(function(calendarWeek, key) {
                              //@ts-ignore
                              return (<React.Fragment key={calendarWeek}>
                              {
                                  Object.keys(billing[accountNumber][calendarWeek]).map(function (row, key) {
                                      return (<IonRow key={key} className={'data-row'}>
                                          <IonCol style={{textAlign: 'center'}}>{calendarWeek}</IonCol>
                                          <IonCol style={{textAlign: 'center'}}>{billing[accountNumber][calendarWeek][row]['instrument']}</IonCol>
                                          <IonCol
                                              className={billing[accountNumber][calendarWeek][row]['tradeResultInstrument'] < 0 ? 'negativeAmount' : 'positiveAmount'}>{billing[accountNumber][calendarWeek][row]['tradeResultInstrument']?.toFixed(2)} €</IonCol>
                                          <IonCol
                                              className={billing[accountNumber][calendarWeek][row]['provisionInstrument'] < 0 ? 'negativeAmount' : 'positiveAmount'}>{billing[accountNumber][calendarWeek][row]['provisionInstrument']?.toFixed(2)} €</IonCol>
                                        </IonRow>
                                          )
                                  })
                              }
                          </React.Fragment>)})}
                      </IonGrid>
                  </IonCardContent>
              </IonCard>);
          })}
      </IonContent>
    </IonPage>
          </>
  );
};

export default Invoice;
